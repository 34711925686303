import cn from "classnames";
import styles from "./Footer.module.scss";
import { navConfig } from "./config";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className={styles["footer"]}>
      <div className={cn("wrapper", styles["footer__wrapper"])}>
        <img className={styles['footer__logo']} src={"/images/logo.svg"} alt="Logo" />
        <div className={styles["footer__content"]}>
          <div>
            <span className={styles["footer__content-item"]}>
              In case you need further clarifications, contact us a{" "}
              <a href="mailto:support@discovenger.com">
                support@discovenger.com
              </a>
            </span>
            <span className={styles["footer__content-item"]}>
              For refunds, contact here{" "}
              <a href="mailto:refund@discovenger.com">refund@discovenger.com</a>
            </span>
            <span className={styles["footer__content-item"]}>
              On issues related to personal data{" "}
              <a href="mailto:dpo@discovenger.com">dpo@discovenger.com</a>
            </span>
            <span
              className={cn(styles["footer__content-item"], styles["address"])}
            >
              <strong>Creative Group Corp s.r.o.</strong>
            </span>
            <span
              className={cn(styles["footer__content-item"], styles["address"])}
            >
              VIkova 532/8, Zizkov, 130 00 Praha 3, Czech Republic
            </span>
            <span
              className={cn(styles["footer__content-item"], styles["address"])}
            >
              Company number: 07317522
            </span>
          </div>
          <NavFooter />
        </div>

        <div className={styles["footer__content-bottom"]}>
          <div>
            <h4 className={styles["title"]}>Secure online payment:</h4>
            <div className={styles["footer__payment-list"]}>
              <figure>
                <img src={"/images/common/payments/visa.svg"} alt="visa" />
              </figure>
              <figure>
                <img
                  src={"/images/common/payments/mastercard.svg"}
                  alt="visa"
                />
              </figure>
              <figure className={styles["wide"]}>
                <img src={"/images/common/payments/american.svg"} alt="visa" />
              </figure>
              <figure className={styles["wide"]}>
                <img src={"/images/common/payments/discover.svg"} alt="visa" />
              </figure>
              <figure>
                <img src={"/images/common/payments/jcb.svg"} alt="visa" />
              </figure>
              <figure className={styles["wide"]}>
                <img src={"/images/common/payments/wire.svg"} alt="visa" />
              </figure>
              <figure>
                <img src={"/images/common/payments/debit.svg"} alt="visa" />
              </figure>
            </div>
          </div>

          <div>
            <h4 className={styles["title"]}>Approved by:</h4>
            <div className={styles["footer__payment-list"]}>
              <figure className={styles["wide"]}>
                <img src={"/images/common/payments/credit.svg"} alt="visa" />
              </figure>
              <figure>
                <img src={"/images/common/payments/pci.svg"} alt="visa" />
              </figure>
              <figure>
                <img src={"/images/common/payments/ssl.svg"} alt="visa" />
              </figure>
            </div>
          </div>
        </div>

        <p className={styles["footer__copy"]}>
          © {new Date().getFullYear()} Discovenger. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

const NavFooter = () => {
  return (
    <div className={styles["footer__nav"]}>
      <h4>Legal Info:</h4>
      <nav>
        <ul className={styles["footer__list"]}>
          {navConfig.map(({ href, text }) => {
            return (
              <li key={href} className={styles["footer__list-item"]}>
                <Link
                  className={styles["footer__list-link"]}
                  to={href}
                  aria-label={text}
                >
                  {text}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Footer;
